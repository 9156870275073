.font-unbolded {
  font-family: Tweaat, sans-serif;
}

.font-bolded {
  font-family: Tweaa, serif;
}

.clash-medium-font {
  font-family: ClashMedium, sans-serif;
}


@font-face {
  font-family: 'ClashBold';
  src: url('fonts/clash/ClashDisplay-Bold.woff2') format('woff2'),
  url('fonts/clash/ClashDisplay-Bold.woff') format('woff'),
  url('fonts/clash/ClashDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashMedium';
  src: url('fonts/clash/ClashDisplay-Medium.woff2') format('woff2'),
  url('fonts/clash/ClashDisplay-Medium.woff') format('woff'),
  url('fonts/clash/ClashDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Tweaa';
  src: url('fonts/tw-cent-mt-bolded/Tw Cen MT Condensed Extra Bold.woff') format('woff')
}
@font-face {
  font-family: 'Tweaat';
  src: url('fonts/tw-cent-mt/Tw Cen MT.woff') format('woff')
}