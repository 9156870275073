.map-with-sidebar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    margin: 20px auto;
    max-width: 1240px;
    gap: 20px;
    min-height: 80vh;
}

.sidebar {
    flex: 1;
    margin: 10px;
    max-width: 300px;
    border-radius: 8px;
    padding: 10px;

    background-color: rgba(0, 0, 0, 0.6);
    border: solid #ffffff 2px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    min-width: 250px;
    box-sizing: border-box;
}

.sidebar h2 {
    margin-top: 0;
    font-size: 1.5em;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar li {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.sidebar li:hover {
    background-color: #ffffff;
    color: black;
}

.sidebar li.selected {
    background-color: #ffffff;
    color: #000000;
    border-color: #000000;
}

.map-card {
    flex: 3;
    margin: 10px;
    border: black solid 1px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    min-height: 400px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .map-with-sidebar {
        flex-direction: column;
        align-items: stretch;
        min-height: auto;
    }

    .sidebar {
        max-width: none;
        margin-bottom: 20px;
        min-width: auto;
    }

    .map-card {
        min-height: 300px;
    }
}

@media (max-width: 480px) {
    .sidebar {
        font-size: 0.9em;
    }

    .sidebar li {
        padding: 8px;
    }

    .map-card {
        min-height: 400px;
    }
}
.info-window-content {
    text-align: center;
    max-width: 90vw;
    max-height: 80vh;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
}

.info-window-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    max-width: 100%;
    max-height: 70vh;
}

@media (min-width: 1200px) {
    .info-window-content {
        max-width: 400px;
        max-height: 300px;
    }

    .info-window-image {
        max-width: 400px;
        max-height: 250px;
    }
}

@media (max-width: 768px) {
    .info-window-content {
        max-width: 80vw;
        max-height: 60vh;
    }

    .info-window-image {
        max-width: 80vw;
        max-height: 50vh;
    }
}

@media (max-width: 480px) {
    .info-window-content {
        max-width: 90vw;
        max-height: 50vh;
    }

    .info-window-image {
        max-width: 90vw;
        max-height: 40vh;
    }
}
