.info-card {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 300px;
    height: 390px;
    margin: auto;
    border: 1px white solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-card-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: white;
}

.info-card-description {
    font-size: 1.1rem;
    color: white;
    margin: auto 0;
}

.info-card-button {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000;
    align-self: center;
}
