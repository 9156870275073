.contact-info-wrapper {
    max-width: 1240px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.contact-info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 20px;
}

.contact-info-card a {
    color: #27b751;
    text-decoration: none;
}

.contact-info-card a:hover {
    color: darkgreen;
}
.contact-info-card {
    flex: 1;
    margin: 0 10px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: 2px solid #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

.section-break {
    min-height: 100px;
}

@media (max-width: 767px) {
    .contact-info-card {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .contact-info-card {
        width: calc(50% - 20px);
        margin-top: 0;
    }
}

@media (max-width: 400px) {
    .contact-info-card {
        margin-bottom: 20px;
        max-width: 95vw;
    }
}
