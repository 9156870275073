.about-us-container {
    padding: 20px;
    max-width: 1240px;
    margin: 0 auto;
}

.about-us-section-title {
    text-align: center;
    font-size: 52px;
    font-weight: bold;
    margin: 40px 0 20px;
    border: solid #ffffff 2px;
    color: white;

    background-color: rgba(30, 119, 69, 0.7);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-product-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.about-us-text-content {
    flex: 1;
    padding-right: 20px;
}

.about-us-image-content {
    flex: 1;
    max-width: 50%;
}

.about-us-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;    color: white;

    border: solid #ffffff 1px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.about-us-paragraph {
    font-size: 22px;
    line-height: 1.6;
    text-align: justify;
    margin: 0;
    color: white;
    background-color: rgba(30, 119, 69, 0.7);
    border-radius: 20px;
    border: solid #ffffff 1px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    padding: 15px;
}

.about-us-info-cards-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .about-us-product-section {
        flex-direction: column-reverse;
        text-align: center;
    }

    .about-us-text-content {
        padding-right: 0;
        margin-top: 20px;
    }

    .about-us-image-content {
        max-width: 100%;
        max-height: 300px;
    }

    .about-us-image {
        max-width: 100%;
    }

    .about-us-paragraph {
        padding: 15px;
    }

    .about-us-info-cards-section {
        flex-direction: column;
        align-items: center;
    }
}
