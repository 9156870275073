.navbar {
    background-color: #026533;
    padding: 1rem 0;
    width: 100%;
    margin-bottom: 100px;
    background-image: url("https://gagalon-images.s3.eu-north-1.amazonaws.com/logos/sash.svg");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: left -10%;
}
.navbar.expanded,
.navbar-no-background {
    background-image: none !important;
}

@media (max-width: 1500px) {
    .navbar {
        background-size: 45%;
        background-position: left -145%;
    }
}

@media (max-width: 1250px) {
    .navbar {
        background-size: 65%;
        background-position: left -80%;
    }
}


@media (max-width: 1000px) {
    .navbar {
        background-size: 80%;
        background-position: left -60%;
    }
}

@media (max-width: 750px) {
    .navbar {
        background-size: 120%;
        background-position: left -80%;
    }
}

@media (max-width: 500px) {
    .navbar {
        background-size: 180%;
        background-position: left -80%;
    }
}

@media (max-width: 350px) {
    .navbar {
        background-size: 220%;
        background-position: left -80%;
    }
}


.container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1240px;
    margin: 0 auto;
}

.navbar-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header-logo {
    height: 70px;
}

.navbar-nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navbar-nav li {
    margin-left: 2rem;
}

.nav-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 23px;
    position: relative;
    font-family: Tweaa, serif;

    transition: color 0.3s ease;
}

.nav-link::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transition: width 0.3s ease;
}

.nav-link:hover {
    color: #d8d8d8;
}

.nav-link:hover::after {
    width: 100%;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 1130px) {
    .container-fluid {
        flex-wrap: wrap;
    }

    .navbar-left {
        flex-grow: 1;
        justify-content: space-between;
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    ul.navbar-nav {
        flex-direction: column;
        width: 100%;
        padding-left: 0;
    }

    .navbar-expand-lg .navbar-collapse {
        display: none !important;
    }
    .navbar-expand-lg .navbar-collapse.collapse.show {
        display: flex !important;
    }
    .navbar-expand-lg .navbar-toggler {
        display: block !important;
    }
}

@media (min-width: 1131px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none !important;
    }
}
