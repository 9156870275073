.blog-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 20px;
}

.blog-title {
    text-align: center;
    margin-bottom: 40px;

    font-size: 52px;
    font-weight: bold;
    color: #ffffff;
    background-color: rgba(30, 119, 69, 0.7);
    border: solid #ffffff 1px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.blog-articles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.small-section-break {
    min-height: 10px;
}
.carousel-image {
    height: 600px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    border: solid #ffffff 1px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}


.carousel-image-classic {
    height: 800px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    border: solid #ffffff 1px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
    .about-us-product-section {
        flex-direction: column;
    }

    .about-us-text-content, .about-us-image-content {
        max-width: 100%;
        padding-right: 0;
    }

    .about-us-image-content {
        margin-top: 20px;
    }

    .carousel-image {
        height: 300px;
        width: 85vw;

    }

    .carousel-image-classic {
        height: 350px;
        width: 85vw;

    }
}

.blog-article {
    background-color: rgba(0, 0, 0, 0.75);
    border: solid #ffffff 1px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 10px;
}

.blog-article h2 {
    font-size: 22px;
    margin-bottom: 10px;
}

.blog-article p {
    font-size: 16px;
    margin-bottom: 20px;
}

.read-more {
    color: #2ee063;
    text-decoration: none;
    font-weight: bold;
}

.read-more:hover {
    text-decoration: underline;
}

.blog-pagination {
    margin-top: 30px;
    text-align: center;
}

.blog-pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #27b751;
    color: white;
    border: solid white 2px;
    border-radius: 5px;
    cursor: pointer;
}

.blog-pagination button.active {
    background-color: #167232;
}

.blog-pagination button:hover:not(.active) {
    background-color: #0056b3;
}

.article-page {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-page h1 {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.article-page p {
    font-size: 18px;
    line-height: 1.6;
}

.back-to-blog {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #007bff;
}

.back-to-blog:hover {
    text-decoration: underline;
}
