body {
    font-family: 'Work Sans', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #d7deaf;
    background-image: url("https://gagalon-images.s3.eu-north-1.amazonaws.com/homepage/background_cut.webp");
    background-size: cover;
    overflow-x: hidden;
}

.main-page {
    padding: 20px;
    max-width: 1240px;
    margin: 0 auto;
}

.hero-section {
    padding: 40px 20px;
    color: #ffffff;
    background-color: rgba(2, 101, 51, 0.7);
    border: solid #ffffff 2px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.5rem;
}

.title {
    border: solid #ffffff 1px;
    background-color: rgba(2, 101, 51, 0.7);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 23px;
}

.section-break {
    height: 50px;
}

.why-us {
    padding: 40px 20px;
    text-align: center;
}

.why-us h1 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
}

.info-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.info-card {
    flex: 1 1 300px;
    max-width: 100%;
    margin: 10px;
}

@media (max-width: 768px) {
    .hero-section {
        padding: 20px;
        border-radius: 15px;
    }


    .hero-title {
        font-size: 2rem;
    }

    .hero-subtitle {
        font-size: 1.2rem;
    }

    .why-us h1 {
        font-size: 2rem;
    }

    .section-break {
        height: 40px;
    }

    .info-cards {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .info-card {
        width: 90%;
        max-width: 400px;
    }
}

@media (max-width: 480px) {
    body {
        background-position: center top;
    }

    .hero-title {
        font-size: 1.8rem;
    }

    .hero-subtitle {
        font-size: 1rem;
    }

    .why-us h1 {
        font-size: 1.5rem;
    }

    .info-card {
        width: 100%;
    }
}
