.footer-container {
    background-color: #026533;
    background-image: url("https://gagalon-images.s3.eu-north-1.amazonaws.com/logos/sash.svg");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: left 150%;
    color: #efeded;
    padding: 2rem;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 100px;
}

@media (max-width: 1500px) {
    .footer-container {
        background-size: 55%;
        background-position: left 150%;
    }
}

@media (max-width: 1250px) {
    .footer-container {
        background-size: 55%;
        background-position: left 120%;
    }
}

@media (max-width: 1000px) {
    .footer-container {
        background-size: 65%;
        background-position: left 120%;
    }
}


@media (max-width: 800px) {
    .footer-container {
        background-image: none;
    }
}


.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1240px;
    margin: 0 auto;
}

.footer-logo {
    flex: 1;
    text-align: left;
}

.footer-icon {
    max-height: 130px;
}

@media (max-width: 768px) {
    .footer-icon {
        max-height: 80px;
    }
}

.footer-links {
    flex: 2;
    display: flex;
    justify-content: center;
    text-align: center;  font-family: Tweaa, serif;

}

.footer-column {
    display: flex;
    flex-direction: column;
    text-align: left;
}

@media (max-width: 768px) {
    .footer-column {
        text-align: center;
    }
}

.footer-separator {
    width: 2px;
    background-color: #ffffff;
    height: auto;
    margin: 0 20px;
}

.footer-horizontal-separator {
    display: none;
    width: 80px;
    height: 2px;
    background-color: #ffffff;
    margin: 20px auto 10px auto;
}

@media (max-width: 768px) {
    .footer-separator {
        display: none;
    }

    .footer-horizontal-separator {
        display: block;
    }
}

.footer-details {
    flex: 1;
    text-align: left;
}

@media (max-width: 768px) {
    .footer-details {
        text-align: center;
    }
}

.footer-heading {
    font-size: 45px;
    letter-spacing: 4px;
    margin: 0;
}

.footer-title {
    font-size: 20px;
    margin-bottom: 1rem;
}

.footer-link {
    font-size: 20px;
    margin-bottom: 0.5rem;
    position: relative;
    cursor: pointer;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #ffffff;
}

.footer-link::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -3px;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease, left 0.3s ease;
}

.footer-link:hover::after {
    width: 100%;
    left: 0;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
        .footer-separator-mobile {
            width: 80px;
            height: 2px;
            margin: 20px auto;
            background-color: #000000;

        }

    .footer-logo {
        margin-bottom: 1.5rem;
    }

    .footer-links {
        margin-bottom: 1.5rem;
    }

    .footer-heading {
        font-size: 35px;
    }

    .footer-title {
        font-size: 18px;
    }

    .footer-link {
        font-size: 18px;
    }
}
