.company-card {
    background-color: rgba(0, 0, 0, 0.7);
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 300px;
    height: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.company-card-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #fff;
}

.company-card-description {
    font-size: 1.1rem;
    color: #fff;
    margin: auto 0;
}

.company-card-message {
    color: #fff;
    font-size: 1.2rem;

}

.company-card-button {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    align-self: center;
}
